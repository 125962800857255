.Blogs {
  padding-top: 10vh;
  display: grid;
  height: 100%;

  padding-bottom: 10vh;
  .blogscontainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media (max-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
    }
    width: auto;
    .card {
      margin: 10%;
      background-color: #f1f5fd;
      border-radius: 20px 20px 20px 20px;
    }
    .card-body {
      padding: 5%;
      height: 100% !important;
    }
    .imgcontainer {
      height: 300px;
      overflow: hidden;
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: #ffffff;
      padding-bottom: 2%;
      border-radius: 20px 20px 0px 0px;
    }
    .card-img,
    .card-img-bottom,
    .card-img-top {
      width: 100%;
      object-fit: cover;
      align-self: center;
      //   padding: 5%;
      //   padding-left: 0%;
      //   padding-right: 0%;
      //   padding-bottom: 2%;
    }
  }
}
