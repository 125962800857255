/*----------------------------------------------
*
* [Default Stylesheet]
*
* Theme    : NEXGEN
* Version  : 1.0
* Author   : Codings
* Support  : codings.dev
* 
----------------------------------------------*/

/*----------------------------------------------

[ALL CONTENTS]

1. Root
2. Button
3. Effect

----------------------------------------------*/

/*----------------------------------------------
1. Root
----------------------------------------------*/

/* #region Root */

:root {
  --h1-font: 'Poppins', sans-serif;
  --h1-size: 4.5rem;
  --h1-weight: 900;
  
  --h2-font: 'Poppins', sans-serif;
  --h2-size: 3rem;
  --h2-weight: 700;
  
  --p-font: 'Montserrat', sans-serif;
  --p-size: 1rem;
  --p-weight: 400;

  --header-bg-color: #21333e;
  --nav-top-bg-color: #21333e;
  --nav-sub-bg-color: #21333e;
  --nav-holder-bg-color: #21333e;
  --nav-brand-height: 35px;
  --nav-item-color: #f9f9f9;
  --hero-bg-color: #111117;
  --footer-brand-height: 80px;

  --primary-color: #058283;
  --secondary-color: #00a6a6;
  
  --white-color: #ffffff;
  --black-color: #000007;
  --dark-grey-color: #111117;

  --primary-t-color: #21333e;
  --secondary-t-color: #f9f9f9; 
  --primary-p-color: #606d75;
  --secondary-p-color: #c0c3d0;
  --primary-b-color: #f9f9f9;
  --primary-l-color: rgba(0, 0, 0, .25);
  --secondary-l-color: rgba(255, 255, 255, 0.35);

  --valid-color: #007a4d;
  --invalid-color: #e34f4f;

  --primary-bg-color: #f9f9f9;
  --primary-bg-color-2: #f1f1f1;
  --primary-bg-color-3: rgba(5, 130, 131, 0.15);

  --secondary-bg-color: #111117;
  --secondary-bg-color-2: #080d10;
  --secondary-bg-color-3: #191919;

  --card-bg-color: #ffffff;

  --footer-bg-color: #111117;
}

/* #endregion Root */

/*----------------------------------------------
2. Button
----------------------------------------------*/

/* #region Button */


/* #endregion Effect */

