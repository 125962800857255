.Exemptions {
  padding: 5%;
  .cardgrid {
    display: grid;
    grid-template-columns: repeat(3, 5fr);
    justify-items: center;
    // 1100
    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 5fr);
    }

    @media (max-width: 740px) {
      grid-template-columns: repeat(2, 5fr);
    }
    .cardx {
      background-color: rgb(255, 255, 255);
      display: flex;
      width: 300px;
      height: 300px;
      margin-bottom: 5vh;

      box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.1);
      border-radius: 7%;
      flex-direction: column;
      justify-content: space-between;
      padding: 10%;
      img {
        width: 50px;
        height: 50px;
      }
      h2 {
        font-size: 1rem;
      }
      @media (max-width: 740px) {
        width: 200px;
        height: 200px;
      }
      @media (max-width: 550px) {
        width: 150px;
        height: 150px;
        margin-bottom: 5vh;

        h2 {
          font-size: 0.71rem;
        }
      }
    }
  }
}
